import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "/tipstricks.png",
        "alt": "BitWiki"
      }}></img></p>
    <h1>{`Bitwig Tips and Tricks`}</h1>
    <p>{`Here we share small tricks you can use in Bitwig Studio. You can also go to the `}<a parentName="p" {...{
        "href": "/wiki/"
      }}>{`BitWiki
Overview`}</a>{` find different Topics. `}</p>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "how-to-navigate-the-entirety-of-the-popup-browser-with-keystrokes"
        }}>{`How to navigate the entirety of the 3.2 popup browser with keystrokes alone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "monitor-bus"
        }}>{`Monitor Bus / Sonarworks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "generating-sub-harmonics-through-re-synthesis"
        }}>{`Generating sub-harmonics on an audio signal (through re-synthesis)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "quick-percussions-slicing"
        }}>{`Quick percussions/slicing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "using-relative-automations-and-clip-launcher"
        }}>{`Using relative automations and clip launcher`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "quantize-audio-so-it-sounds-clean-without-warping"
        }}>{`Quantize audio so it sounds clean without warping`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mute-disable"
        }}>{`Mute/disable`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "macros-to-automate-everything"
        }}>{`Macros to automate everything`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "50-limitation-relative-automations"
        }}>{`50% limitation relative automations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "extracting-data-from-the-grid"
        }}>{`Extracting data from The Grid`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "track-freeze"
        }}>{`Track Freeze`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "switching-tracks"
        }}>{`Switching Tracks`}</a></li>
    </ul>
    <h2>{`Need more?`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/"
        }}>{`BitWiki Overview`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      